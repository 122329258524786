import {
  type UserLocale,
  type UserLocation,
  GetPsaDismissedDocument,
  GetUserLocaleDocument,
  GetUserLocationDocument,
} from '@kijiji/generated/graphql-types'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { PSA_BANNER_KEYS } from '@/constants/psaBanner'
import { getLocale } from '@/domain/locale'
import { getInitialUserLocation } from '@/domain/location/getInitialUserLocation'

import { type RequestHeaders } from './apolloClient'

const localeInitialCookieData = (cookies?: RequestHeaders['cookies']): UserLocale => {
  const localeFromCookie = cookies?.[CookieRegistry.SITE_LOCALE]
  const { apiLocale, cookieLocale, languageKey, routeLocale } = getLocale(localeFromCookie)

  return { apiLocale, cookieLocale, languageKey, routeLocale }
}

const locationInitialCookieData = (cookies?: RequestHeaders['cookies']): UserLocation => {
  const userPreferenceCookie = cookies?.[CookieRegistry.USER_PREFERENCES]
  const location = getInitialUserLocation(userPreferenceCookie)

  return location
}

const psaInitialCookieData = (cookies?: RequestHeaders['cookies']) => {
  const dismissedCookie = cookies && cookies[CookieRegistry.HAS_DISMISSED_PSA_BANNER]

  const dismissedKeys = (dismissedCookie && decodeURIComponent(dismissedCookie)) || ''

  return {
    appDownloadPSA: dismissedKeys.includes(PSA_BANNER_KEYS.APP_DOWNLOAD),
    siteMaintenancePSA_may7th: dismissedKeys.includes(PSA_BANNER_KEYS.SITE_MAINTENANCE),
  }
}

export const getInitialCookieCacheData = (cookies?: RequestHeaders['cookies']) => {
  const psaInitialStates = psaInitialCookieData(cookies)
  const locationInitialStates = locationInitialCookieData(cookies)
  const localeInitialStates = localeInitialCookieData(cookies)

  return [
    {
      query: GetPsaDismissedDocument,
      data: { dismissedStates: psaInitialStates },
    },
    {
      query: GetUserLocationDocument,
      data: { userLocation: locationInitialStates },
    },
    {
      query: GetUserLocaleDocument,
      data: { userLocale: localeInitialStates },
    },
  ]
}
