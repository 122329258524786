import {
  ProvinceAbbreviation,
  useGetGeocodeReverseFromIpQuery,
} from '@kijiji/generated/graphql-types'
import { useDecision } from '@optimizely/react-sdk'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'next-i18next'
import { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { HTTP_STATUS } from '@/constants/httpStatus'
import useCookiePreferences from '@/hooks/useCookiePreferences'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { createCookie } from '@/utils/cookies/createCookie'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'
import { getGqlErrorStatusCode } from '@/utils/errors'
import { isServer } from '@/utils/isSSR'
import { sendToLogger } from '@/utils/sendToLogger'

import { LinkCustom } from '../link-custom/LinkCustom'
import { CookieConsentModal } from './CookieConsentModal'
import {
  CookieAcknowledgementWrapper,
  CookieBannerBackgroundOverlay,
  CookieBannerCloseButton,
  CookieBannerContent,
  CookieBannerDescription,
  CookieConsentButtons,
  CookieConsentContent,
} from './styled'

/* We conditionally load the cookies banner depending on whether the user has a Quebec
IP address. Consequently, lazy loading our components such that they are imported
only when rendered reduces the banner's effect on our CWV (core web vitals) */
const BodyText = dynamic(() => import('@/ui/atoms/body-text').then((module) => module.BodyText), {
  ssr: false,
})
const Button = dynamic(() => import('@/ui/atoms/button').then((module) => module.Button), {
  ssr: false,
})
const Drawer = dynamic(() => import('@/ui/atoms/drawer').then((module) => module.Drawer), {
  ssr: false,
})
const HeadlineText = dynamic(
  () => import('@/ui/atoms/headline-text').then((module) => module.HeadlineText),
  {
    ssr: false,
  }
)
const Spacing = dynamic(() => import('@/ui/atoms/spacing').then((module) => module.Spacing), {
  ssr: false,
})

export type AcceptAllSource = 'acceptButton' | 'consentModal'

export const CookiePermissions = () => {
  const [featureToggle] = useDecision(FEATURE_FLAG.COOKIES_ACKNOWLEDGEMENT_BANNER)
  const cookieConsentBannerEnabled = process.env.NEXT_PUBLIC_USE_COOKIE_PREFERENCES === 'true'
  const { getCookiePreferences, setCookiePreferences } = useCookiePreferences()
  const [shouldShowBanner, setShouldShowBanner] = useState(false)
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false)
  const { pathname } = useRouter()
  const { t } = useTranslation('common')
  const theme = useContext(ThemeContext)

  /**
   * We shouldn't show the cookie acknowledgement banner if it has been previously dismissed.
   * If the user has dismissed the banner in the past, then the geocode query should not be called.
   * */
  const hasUserDismissedBanner = isServer()
    ? undefined
    : getCookieByKey(document.cookie, CookieRegistry.HAS_DISMISSED_COOKIE_ACKNOWLEDGEMENT_BANNER)

  /**
   * We shouldn't show the cookie consent banner if the user has already set their cookie preferences.
   */
  const hasUserSetPreferences = isServer() ? undefined : !!getCookiePreferences()

  const showCookieAcknowledgementBanner =
    featureToggle.enabled && !cookieConsentBannerEnabled && hasUserDismissedBanner !== 'true'

  const showCookieConsentBanner = cookieConsentBannerEnabled && !hasUserSetPreferences

  /* NOTE: The cookie banner will not appear if the geocode reverse from IP endpoint returns an
  error. There may be legal implications if the user is, in fact, located in Quebec. */
  useGetGeocodeReverseFromIpQuery({
    skip: !showCookieAcknowledgementBanner && !showCookieConsentBanner,
    ssr: false,
    onCompleted: (data) => {
      setShouldShowBanner(data.geocodeReverseFromIp.province === ProvinceAbbreviation.Qc)
    },
    onError: (error) => {
      if (getGqlErrorStatusCode(error) !== HTTP_STATUS.NOT_FOUND) {
        sendToLogger(error, {
          tags: { fn: 'getUserLocation', component: 'CookiePermissions' },
          fingerprint: ['CookiePermissions'],
        })
      }
    },
  })

  const onCloseBanner = () => {
    if (cookieConsentBannerEnabled) {
      setShouldShowBanner(false)
      return
    }

    createCookie(CookieRegistry.HAS_DISMISSED_COOKIE_ACKNOWLEDGEMENT_BANNER, 'true')

    trackEvent({ action: GA_EVENT.CookieAcknowledge, label: `loc=${pathname}` })
    setShouldShowBanner(false)
  }

  const handleAcceptAll = (source: AcceptAllSource) => {
    setCookiePreferences({
      optOutTargeting: false,
      optOutAnalyticsAndPerformance: false,
    })

    if (source === 'acceptButton') {
      trackEvent({ action: GA_EVENT.CookieAcknowledge, label: 'cta=acknowledge_all' })
      setShouldShowBanner(false)
    } else if (source === 'consentModal') {
      trackEvent({
        action: GA_EVENT.CookieAcceptAll,
        label: `cta=accept_all;loc=${pathname};FunctionalCookie=1;AnalyticsCookie=1;TargetingCookie=1`,
      })
      setIsConsentModalOpen(false)
    }
  }

  const handleManagePreferences = () => {
    trackEvent({
      action: GA_EVENT.CookieManagePreferences,
      label: `cta=manage_cookies;loc=${pathname}`,
    })
    setShouldShowBanner(false)
    setIsConsentModalOpen(true)
  }

  return (
    <>
      {cookieConsentBannerEnabled ? (
        <>
          <Drawer
            label="Cookies consent banner"
            closeButtonLabel={t('cookies.banner.close')}
            isOpen={shouldShowBanner}
            onCancel={onCloseBanner}
          >
            <Spacing mBottom={theme.spacing.large}>
              <HeadlineText size="large" as="h2">
                {t('cookies.consent.title')}
              </HeadlineText>
            </Spacing>
            <CookieConsentContent data-testid="cookie-consent-banner">
              <BodyText size="small">
                {t('cookies.consent.description')}
                <LinkCustom
                  hasUnderline
                  key="cookiePolicyLink"
                  rel="noreferrer"
                  size="small"
                  target="_blank"
                  variant="secondary"
                  href={`${t('cookies.banner.cookie_policy.kijiji.href')}`}
                >
                  {t('cookies.consent.policy')}
                </LinkCustom>
                .
              </BodyText>
              <CookieConsentButtons>
                <Button variant="secondary" size="small" onClick={handleManagePreferences}>
                  {t('cookies.consent.manage')}
                </Button>
                <Button size="small" onClick={() => handleAcceptAll('acceptButton')}>
                  {t('cookies.consent.accept')}
                </Button>
              </CookieConsentButtons>
            </CookieConsentContent>
          </Drawer>
          <CookieConsentModal
            isOpen={isConsentModalOpen}
            handleAcceptAll={handleAcceptAll}
            handleClose={() => setIsConsentModalOpen(false)}
          />
        </>
      ) : (
        <CookieAcknowledgementWrapper isOpen={shouldShowBanner}>
          <CookieBannerBackgroundOverlay />
          <CookieBannerContent data-testid="cookie-acknowledgement-banner">
            <CookieBannerDescription size="small">
              <Trans
                i18nKey="common:cookies.banner.description"
                components={[
                  <LinkCustom
                    hasUnderline
                    href={`${t('cookies.banner.cookie_policy.kijiji.href')}`}
                    key="cookiePolicyLink"
                    rel="noreferrer"
                    size="small"
                    target="_blank"
                    variant="secondary"
                    noChildren={true}
                  />,
                  <LinkCustom
                    hasUnderline
                    href={`${t('cookies.banner.privacy_policy.kijiji.href')}`}
                    key="privacyPolicyLink"
                    rel="noreferrer"
                    size="small"
                    target="_blank"
                    variant="secondary"
                    noChildren={true}
                  />,
                ]}
              />
            </CookieBannerDescription>
            <CookieBannerCloseButton onClick={onCloseBanner}>
              {t('cookies.banner.close')}
            </CookieBannerCloseButton>
          </CookieBannerContent>
        </CookieAcknowledgementWrapper>
      )}
    </>
  )
}
